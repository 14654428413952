<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="relative bg-sozogen-purple pt-8 pb-6">
  <div
    class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-200 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap text-center lg:text-left">
      <div class="w-full lg:w-6/12 px-4">
        <h4 class="text-3xl text-sozogen-gold font-semibold">Let's keep in touch!</h4>
        <h5 class="text-lg mt-0 mb-2 text-sozogen-light">
          Reach out via email at <a href="mailto:contact@sozogen.com">contact@sozogen.com</a>
        </h5>        
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="flex flex-wrap items-top mb-6">
          <div class="w-full lg:w-4/12 px-4 ml-auto">
            <span
              class="block uppercase text-sozogen-gold text-sm font-semibold mb-2"
            >
              Useful Links
            </span>
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-sozogen-light hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                  href="https://www.github.com/SozogenInc"
                >
                  Github
                </a>
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-sozogen-light hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                  href=""
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  class="text-sozogen-light hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                  href=""
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-6 border-blueGray-300" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-5/12 px-4 mx-auto text-center">
        <div class="text-sm text-sozogen-light font-semibold py-1">
          Copyright © {date} Sozogen, Inc.
        </div>
      </div>
    </div>
  </div>
</footer>
