<script>
  import { link } from "svelte-routing";

  // core components
  import AuthNavbar from "components/Navbars/AuthNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const team1 = "/assets/img/David.png";
  const team2 = "/assets/img/randall.jpg";
  const team3 = "/assets/img/ryan.png";
  const team4 = "/assets/img/colby.png";
</script>

<div>
  <AuthNavbar />
  <main>
    <div
      class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="background-image: url(assets/img/backg-marbel-dark.png); transform: rotate(180deg);"
      >
      <span
      id="blackOverlay"
      class="w-full h-full absolute opacity-50 bg-black"
    ></span>
      </div>
      <style>
        .gradientText {
          background-size: 300% auto;
          transition: background-position 1s ease-in-out;
        }
        .gradientText:hover {
          background-image: linear-gradient(to right,#CBFFF8, #CB00F8, #CBFFF8, #FFF, #CB00F8, #CBFFF8);
          background-position: right center;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }
      </style>
      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <div class="relative">
              <img src="assets/img/logo_vert_white.svg" alt="Sozogen Logo" class=" top-0 mt-0 mx-auto h-50 max-h-60">
              <h1 class="text-white font-semibold text-xl pt-3 gradientText">
                AUTOMATED AGENT HYGIENE
              </h1>
              <p class="mt-4 text-lg text-blueGray-200">
                The only purpose built solution to automatically detect and repair critical IT and Security services.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>

    <section class="pb-20 bg-blueGray-200 -mt-24">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap">
          <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-sozogen-dark w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-sozogen-gold"
                >
                  <i class="fas fa-shield-alt"></i>
                </div>
                <h6 class="text-xl text-sozogen-gold font-semibold">Continuous Protection</h6>
                <p class="mt-2 mb-4 text-sozogen-light">
                  Monitors your tech stack 24-7, ensuring that your tools are always working on all of your devices
                </p>
              </div>
            </div>
          </div>

          <div class="w-full md:w-4/12 px-4 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-sozogen-light w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto">
                <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-sozogen-gold"
                >
                  <i class="fas fa-retweet"></i>
                </div>
                <h6 class="text-xl font-semibold">Agentless Repair</h6>
                <p class="mt-2 mb-4 text-blueGray-500">
                  Repairs your faulty agents and services utilizing your existing tools. <br> No installation required
                </p>
              </div>
            </div>
          </div>

          <div class="pt-6 w-full md:w-4/12 px-4 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-sozogen-dark w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto">
                <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-sozogen-gold"
                >
                  <i class="fas fa-table"></i>
                </div>
                <h6 class="text-xl text-sozogen-gold font-semibold">Asset Visibility</h6>
                <p class="mt-2 mb-6 text-sozogen-light">
                  Live CMDB, providing unmatched visibility to your assets. <br> A CAASM tool - purpose built to take action
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center mt-32">
          <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fab fa-connectdevelop text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Mending your Digital Cracks
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              Our innovative solution is purpose built to fill the gaps caused by malfunctioning agents. 
            </p>
            <p
              class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
            >
              We set-out to solve our own problem in IT and Security, where the tools we deployed to protect and support the business, were often found offline, with their last check-in being 2 weeks ago, or never even checked in at all.
          </p>
            <a use:link href="/" class="font-bold text-sozogen-purple mt-8">
              We fixed that.
            </a>
          </div>

          <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
            <div
              class="relative flex flex-col min-w-0 break-words bg-sozogen-purple w-full mb-6 shadow-lg rounded-lg bg-sozogen-purple"
            >
              <img
                alt="..."
                src="assets/img/kintsugi-heart.jpeg"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    class="text-sozogen-gold fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Agent Healing and Restoration
                </h4>
                <p class="text-md font-light mt-2 text-white">
                  Agents are the vital organs in your IT and Security operations body. IT and Security operators quite literally perform surgery on computers with faulty agents. Diagnosing and repairing with the simplest of actions (the ol'reboot) or more destructive means of rip and replace. We keep your computers healthy, always.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="relative py-20 bg-sozogen-dark">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-sozogen-dark fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-lg"
              src="https://images.unsplash.com/photo-1558464678-2f1570e8d60e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1911&q=80"
            />
          </div>
          <div class="w-full md:w-5/12 ml-auto mr-auto mt-4 px-4">
            <div class="md:pr-12">
              <div
                class="text-sozogen-gHotBlue p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-sozogen-gold"
              >
                <i class="fas fa-fingerprint text-xl"></i>
              </div>
              <h3 class="text-3xl text-sozogen-gold font-semibold">Lets do better together!</h3>
              <p class="mt-4 text-lg leading-relaxed text-sozogen-light">
                We want to support your success in any way we can, let us know what problems you need solved. We're here to help!
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-sozogen-gold bg-sozogen-purple mr-3"
                      >
                        <i class="fas fa-credit-card"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-sozogen-light">
                        We made Sozogen to be affordable at any scale
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-sozogen-gold bg-sozogen-purple mr-3"
                      >
                        <i class="fas fa-dot-circle"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-sozogen-light">
                        Sozogen is a quick and easy win for you and your team!
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt-20 pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-24">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold">Meet the team!</h2>
            <p class="text-lg leading-relaxed m-4 text-blueGray-500">
              We're a small crew of IT and Security professionals, please reach out! We'd love to get to know you.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap justify-center">
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{team1}"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl text-sozogen-dark font-bold">David van Heerden</h5>
                <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                  Founder & CEO
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{team2}"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Randall Pipkin</h5>
                <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                  Founder & CTO
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{team3}"
                class="shadow-lg rounded-full mx-auto max-w-120-px"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Ryan Braunstein</h5>
                <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                  Founder & CISO
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
     </section>
  </main>
  <Footer />
</div>
