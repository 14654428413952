<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Privacy from "./views/Privacy.svelte"

  export let url = "";
</script>

<Router url="{url}">
  <!-- auth layout -->
  <Route path="auth/*auth" component="{Auth}" />
  <Route path="/" component="{Index}" />
</Router>