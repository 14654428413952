<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import AuthNavbar from "components/Navbars/AuthNavbar.svelte";
  import FooterSmall from "components/Footers/FooterSmall.svelte";

  // pages for this layout
  import Register from "views/auth/Register.svelte";

  const registerBg2 = "../assets/img/networkBg.jpg";
  export let location;
  export let auth = "";
</script>

<div>
  <AuthNavbar />
  <main>
    <section class="relative w-full h-full bg-sozogen-dark py-40 min-h-screen">
      <div
        class="absolute top-0 w-full h-full bg-no-repeat bg-full"
        style="background-image: url({registerBg2}); background-size: auto; background-repeat: repeat; opacity: .1;"
      ></div>
      <Router url="auth">
        <Route path="register" component="{Register}" />
      </Router>
      <FooterSmall absolute="true" />
    </section>
  </main>
</div>
